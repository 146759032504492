import { Language } from "../interfaces/language.interface";

export const LANGUAGES_OPTIONS: string[] = ["ar", "de", "en", "fr"];

export const DEFAULT_PEOPLE_MATE_LANGUAGE: Language = {
  code: "en",
  flag: "../../../assets/img/flags/United_Kingdom.svg",
  label: "English",
  base_language_label: "English",
};

export const LANGUAGES_LIST_DEFINITION: Language[] = [
  DEFAULT_PEOPLE_MATE_LANGUAGE,
  {
    code: "ar",
    flag: "../../../assets/img/flags/Egypt.svg",
    label: "عربي",
    base_language_label: "Arabic",
  },
  // {
  //   code: "de",
  //   flag: "../../../assets/img/flags/Germany.svg",
  //   label: "German",
  //   base_language_label: "German",
  // },
  // {
  //   code: "fr",
  //   flag: "../../../assets/img/flags/French.svg",
  //   label: "French",
  //   base_language_label: "French",
  // },
];

export function COMPANY_LANGUAGE(code: string): Language {
  return LANGUAGES_LIST_DEFINITION.find(lang => lang.code === code) || ({} as Language);
}
